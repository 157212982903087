<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';
import { store } from './store.js';



export default {
  name: 'App',
  props: {
      msg: String
  },
  mounted() {
  },
  data() {
      return {
        store: store,
        storeState: store.state,
      }
  },
  methods: {
    getApiUrl(endpoint) {
      if (process.env.NODE_ENV != 'production') return `http://127.0.0.1:5000/${endpoint}`
      return `https://paper-api.onrender.com/${endpoint}`
    },
  }
}
</script>

<style>
  @import './assets/styles/styles.css';
</style>
