<template>
  <div class="">
    <!-- HERO -->
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative bg-gray-50 overflow-hidden">
      <!-- <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
        <div class="relative h-full max-w-7xl mx-auto">
          <svg class="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
          </svg>
        </div>
      </div> -->
      <!-- {{this.storeState.user}} {{this.storeState.isLoggedIn}} -->
      <!-- {{storeState}} -->

      <div class="relative pt-6 pb-16 sm:pb-24">
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
          <nav class="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
            <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <a class="cursor-pointer">
                  <span class="sr-only">Stopping</span>
                  <!-- <img class="h-8 w-auto sm:h-10" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt=""> -->
                  <img class="h-8 w-auto sm:h-10" src="/stopping.png" alt="Logo">
                </a>
              </div>
            </div>
            <!-- <div class="hidden md:flex md:space-x-10">
              <a href="#" class="font-medium text-gray-500 hover:text-gray-900">Product</a>

              <a href="#" class="font-medium text-gray-500 hover:text-gray-900">Features</a>

              <a href="#" class="font-medium text-gray-500 hover:text-gray-900">Marketplace</a>

              <a href="#" class="font-medium text-gray-500 hover:text-gray-900">Company</a>
            </div> -->
            <div class="md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <!-- <span class="inline-flex rounded-md shadow">
                <a v-if="!storeState.isLoggedIn" @click="login" class="inline-flex cursor-pointer items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50">
                  Log in
                </a>
                <a v-else @click="logout" class="inline-flex cursor-pointer items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50">
                  Log out
                </a>
              </span> -->
            </div>
          </nav>
        </div>

        <!--
          Mobile menu, show/hide based on menu open state.

          Entering: "duration-150 ease-out"
            From: "opacity-0 scale-95"
            To: "opacity-100 scale-100"
          Leaving: "duration-100 ease-in"
            From: "opacity-100 scale-100"
            To: "opacity-0 scale-95"
        -->


        <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div class="text-center">
            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span id="typed" class="inline">Stop drinking </span>
              <span class="inline text-blue-500 underline">together</span>
            </h1>
            <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Quit drinking with the support of a small group (your "cohort"). 
              It's <span class="underline">free to participate</span> and there's 
              <span class="underline">no app to download</span>. 
              All communication is anonymous and done through SMS 💬. 
              You can do this, we're cheering for you 🎉.
            </p>
            <!-- <p>
              <ul>
                <li>
                  Enter your number below
                </li>
                <li>
                  get placed in a group
                </li>
              </ul>
            </p> -->
            <div class="sm:text-center lg:text-left mt-8 lg:grid lg:grid-cols-8">
              <div class="col-span-2"></div>
              <div class="bg-white border col-span-4 flex flex-1 max-w-md mx-auto rounded">
                <form class="w-full flex md:ml-0" action="#" method="GET">
                  <label for="search_field" class="sr-only">Search</label>
                  <div class="relative w-full text-gray-400 focus-within:text-gray-600 bg-gray-50">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                      <!-- Heroicon name: solid/search -->
                      <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                        </path>
                      </svg>
                    </div>
                    <input 
                      autocomplete="off" 
                      id="phoneNumber"
                      ref="phoneNumber"
                      class="border rounded block text-xl w-full border-transparent py-2 pl-10 pr-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent" 
                      placeholder="Phone Number" 
                      @focus="onPhoneFocus"
                      v-model="phoneNumber"
                    >
                  </div>
                </form>
              </div>
              <div class="col-span-2"></div>
            </div>
            <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div class="rounded-md shadow">
                <a @click="joinNow" class="md:w-48 w-auto cursor-pointer flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 md:py-4 md:text-lg md:px-10">
                  Join a group
                </a>
              </div>
              <div v-if="phoneError">
                Enter you phone number first.
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>

    <!-- FEATURE1 -->
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative bg-white pt-16 pb-32 overflow-hidden">
      <div class="relative">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:ml-16 lg:px-0">
            <div>
              <div>
                <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gray-900">
                  <!-- Heroicon name: outline/inbox -->
                  <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z">
                    </path>
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                  Motivate each other to control or stop drinking
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Get advice, share your experiences and stories, and encourage others who are trying to quit or cut down.

                </p>
                <div class="mt-6">
                  <a @click="joinNow" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-gray-700">
                    Join a group
                  </a>
                </div>
              </div>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p class="text-base text-gray-500">
                    &ldquo;What finally worked for me was quitting with a group of people that I felt accountable to.
                    There's a dozen little things that helped along the way, but having support from people that were also 
                    stuggling made the difference.
                    &rdquo;
                  </p>
                </div>
                <footer class="mt-3">
                  <div class="flex items-center space-x-3">
                    <!-- <div class="flex-shrink-0">
                      <img class="h-6 w-6 rounded-full" src="/.png" alt="">
                    </div> -->
                    <div class="text-base font-medium text-gray-700">
                      Sam, Memphis TN
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0">
            <div class="flex justify-center mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div class="ml-0 pr-4 lg:mr-8 sm:pr-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img class="max-w-sm w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:w-auto" src="/text1.png" alt="Customer profile user interface">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-24">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 lg:mr-16">
          <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gray-900">
                  <!-- Heroicon name: chart -->
                  <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z">
                    </path>
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                  Reminders, tips, progress tracking
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Automatically track progress and stay motivated. 
                  Get tips to reduce cravings and make it easier. 
                  This shit is hard, don't go it alone. Together, we got this.
                </p>
                <div class="mt-6">
                  <a @click="joinNow" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-gray-700">
                    Join a group
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div class="ml-0 pr-4 lg:mr-8 sm:pr-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img class="max-w-sm w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:w-auto" src="/text2.png" alt="">
              <img 
                class="" src="/fresh-prince-and-carlton-dance.gif" alt=""
                style="margin-top: -305px;width: 250px;margin-left: 25px;border-radius: 5px;position: relative;top: 0;"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- END FEATURE1 -->

    <!-- END HERO -->
    <!-- CALCULATOR -->

    <!-- END CALCULATOR -->
    <!-- FAQ -->
    <div class="bg-gray-900">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-white">
          Frequently asked questions
        </h2>
        <div class="mt-6 border-t border-indigo-400 border-opacity-25 pt-10">
          <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                What is this?
              </dt>
              <dd class="text-base leading-6 text-gray-400">
                It's easier to stop drinking with support and accountability. 
                <span class="font-bold">Stopping</span> places you in a group of people that are quitting at the same time as you. 
                It's free to participate and there's no app to download. 
                You might have heard of "cohort-based learning". This is "cohort-based quitting".
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                How do I interact with the group?
              </dt>
              <dd class="text-base leading-6 text-gray-400">
                All communication is anonymous and done through SMS.
                You send and receive messages thru a number assigned to your group.
                Your number is never shared with other members of your group.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                How much does this cost?
              </dt>
              <dd class="text-base leading-6 text-gray-400">
                It's free to participate. We only accept donations.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                Is this a non-profit?
              </dt>
              <dd class="text-base leading-6 text-gray-400">
                Not yet, but I'm considering it. Right now I want to see if this will help people.
              </dd>
            </div>
            <div class="space-y-2">
              <dt class="text-lg leading-6 font-medium text-white">
                How does this help me quit?
              </dt>
              <dd class="text-base leading-6 text-gray-400">
                Talking to others going through the same thing is a 
                <a href="https://www.nytimes.com/2020/04/03/smarter-living/talking-out-problems.html">
                  good way to overcome
                </a> a challenge.
                We also send small tips along the waye 
                (e.g. 
                  <a href="https://www.dollarshaveclub.com/content/story/how-does-seltzer-manage-to-trick-your-brain">
                    drinking seltzer reduces cravings
                  </a>
                )
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <!-- END FAQ -->
    <!-- ABOUT US -->


    <!-- END ABOUT US -->
    <!-- FOOTER -->
    <footer class="bg-white">
      <div class="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
        <!-- <div class="mt-8 flex justify-center space-x-6">
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Instagram</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">GitHub</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
            </svg>
          </a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Dribbble</span>
            <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" />
            </svg>
          </a>
        </div> -->
        <p class="mt-8 text-center text-base leading-6 text-gray-400">
          &copy; 2021 Stopping, Inc. All rights reserved.
        </p>
      </div>
    </footer>

    <!-- END FOOTER -->
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import Typed from 'typed.js';
import axios from 'axios';
import { store } from '../store.js';
import PaperSelect from '@/components/PaperSelect.vue'
import PaperToggle from '@/components/PaperToggle.vue'

export default {
  name: 'Landing',
  props: {
      msg: String
  },
  components: {
    // PaperMenu,
    PaperSelect,
    PaperToggle,
  },
  created() {
    this.emitter.on('user-data', () => this.onUserData());
    this.revenue = this.revenueOptions[0]
    this.product = this.productOptions[0]
    this.rating = this.ratingOptions[0]
  },
  mounted() {
    this.getFunders()
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        phoneNumber: '',
        phoneError: false,
        selectAll: false,
        revenueOptions: [
          {name: 'All', value: -1},
          {name: '< $100k', value: 100000},
          {name: '$100k - $500k', value: 500000},
          {name: '$500k - $1M', value: 1000000},
          {name: '$1 - $5M', value: 1000000},
          {name: '$5 - $10M', value: 5000000},
          {name: '$10M+', value: 10000000},
        ],
        revenue: {name: 'All', value: -1},
        productOptions: [
          {name: 'All', value: 'all'},
          {name: 'Credit Card', value: 'Credit Card'},
          {name: 'Factoring', value: 'Factoring'},
          {name: 'Revenue Based Financing', value: 'Revenue Based Financing'},
          {name: 'Loan', value: 'Term Loan'},
        ],
        product: {name: 'All', value: 'all'},
        ratingOptions: [
          {name: 'All', value: 'all'},
          {name: 'A', value: 'A'},
          {name: 'B', value: 'B'},
          {name: 'C+', value: 'C'},
        ],
        rating: {name: 'All', value: 'all'},
        locationOptions: [
          {name: 'All', value: 'all'},
          {name: 'United States', value: 'us'},
          {name: 'United Kingdom', value: 'uk'},
          {name: 'Germany', value: 'germany'},
        ],
        location: {name: 'All', value: 'all'},
        searchFocused: true,
        openSideBar: false,
        searchTerm: '',
        section: 'funders',
        showUserMenu: false,
        gotFunders: false,
        companyStage: 'scale',
        funderColumns: ['name', 'paper_rank', 'max_loan_amount', 'min_loan_amount', 'min_annual_revenue'],
        funderCashColumns: ['max_loan_amount', 'min_loan_amount', 'min_annual_revenue'],
        // detailCols: ['']
        columnLabels: {
          name: 'Funder',
          min_loan_amount: 'Revenue'
        },
        funderData: reactive([]),
        filteredFunders: [],
        appError: false,
        submitted: false,
        updatingPayoutPeriod: false,
        loan: {
          email: '',
          mrr: 50000,
          amount: 250000,
          rate: .15,
          multiple: 15,
          payoutPeriod: {label: '12 months', months: 12},
          term: 36,
          paybackMonths: 36,
          years: [
            {label: 'Year 1'},
            {label: 'Year 2'},
            {label: 'Year 3'},
            {label: 'Total'},
          ],
          months: [],
          metricCalcType: 'stripe',
          mrrs: [
            {label: 'Current MRR', value: 0},
            {label: '3 months ago', value: 0},
            {label: '6 months ago', value: 0},
            {label: '12 months ago', value: 0},
            {label: '24 months ago', value: 0},
          ]
        },
        payoutPeriods: [
          {label: '12 months', months: 12},
          {label: '18 months', months: 18},
          {label: '24 months', months: 24}
        ],
      }
  },
  methods: {
    onUserData() {
      this.updateSavedOnFunders()
      this.filterFunders()
    },
    passingSelected(value) {
      console.log("value", value)
    },
    selectAllNow() {
      for (let index = 0; index < this.filteredFunders.length; index++) {
        this.filteredFunders[index].saved = this.selectAll
      }
      this.$forceUpdate()
    },
    joinNow() {
      this.phoneError = false
      if (this.phoneNumber.length < 10) {
        this.phoneError = true
        this.$refs.phoneNumber.focus()
        return
      }
      const path = this.getApiUrl('sa_add_user')
      let d = {phoneNumber: this.phoneNumber}
      axios.post(path, d)
        .then((res) => {
          console.log('got sa_add_user: ', res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    onPhoneFocus() {
      this.searchFocused = true
    },
    clearFilters() {
      this.searchTerm = ''
    },
    login() {
      this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
    },
    logout() {
      this.$router.push({ name: 'Logout', query: { goto: 'Landing' }})
    },
    getGeoSummary(row) {
      if (row.data.geo.city && row.data.geo.countryCode === 'US') return `${row.data.geo.city}, ${row.data.geo.state}`
      if (row.data.geo.city && row.data.geo.state) return `${row.data.geo.city}, ${row.data.geo.state}`
      if (row.data.geo.state) return `${row.data.geo.state}`
      return row.data.geo
      // return row.data.geo.country
      
    },
    countryCodeEmoji(cc) {
      if (!cc) {
        cc = 'US'
      } else if (!cc.geo) {
        cc = 'US'
      } else if (!cc.geo.countryCode) {
        cc = 'US'
      } else {
        cc = cc.geo.countryCode
      }
      const CC_REGEX = /^[a-z]{2}$/i;
      const OFFSET = 127397;
      if (!CC_REGEX.test(cc)) {
        const type = typeof cc;
        throw new TypeError(
          `cc argument must be an ISO 3166-1 alpha-2 string, but got '${
            type === 'string' ? cc : type
          }' instead.`,
        );
      }
      const chars = [...cc.toUpperCase()].map(c => c.charCodeAt() + OFFSET);
      return String.fromCodePoint(...chars);
    },
    formatRank(rank) {
      if (rank < 60) {
        return 'F'
      } else if (rank < 70) {
        return 'D'
      } else if (rank < 80) {
        return 'C'
      } else if (rank < 90) {
        return 'B'
      } else if (rank < 90) {
        return 'A'
      }
      return 'A'
    },
    setRowOpen(clickIndex, row, event) {
      if (event.target.dataset.onclick) {
        return
      }
      let newStatus = !row.open
      for (let index = 0; index < this.filteredFunders.length; index++) {
        this.filteredFunders[index].open = false
      }
      this.filteredFunders[clickIndex].open = newStatus
    },
    formatCash(cash) {
      if (cash >= 1000000) return `$${(cash/1000000).toFixed(0)}M`
      return `$${(cash/1000).toFixed(0)}K`
    },
    getApiUrl(endpoint) {
      if (process.env.NODE_ENV != 'production') return `http://127.0.0.1:5000/${endpoint}`
      return `https://paper-api.onrender.com/${endpoint}`
    },
    updateSavedFunders() {
      this.storeState.userData.savedFunders = []
      for (let index = 0; index < this.filteredFunders.length; index++) {
        const row = this.filteredFunders[index];
        if (row.saved) this.storeState.userData.savedFunders.push(row.public_id)
      }
      this.updateUserData()
    },
    updateSavedOnFunders() {
      for (let index = 0; index < this.funderData.length; index++) {
        const f = this.funderData[index]
        if (this.storeState.userData.savedFunders.includes(f.public_id)) f.saved = true
      }
    },
    saveFunder(row) {
      row.saved = true
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
      }
    },
    updateUserData() {
      if (!this.storeState.isLoggedIn || !this.storeState.gotUserData) return
      const path = this.getApiUrl('update_user_data')
      let d = {user: this.storeState.user, userData: this.storeState.userData}
      axios.post(path, d)
        .then((res) => {
          console.log('got update_user_data: ', res.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    removeFunder(row) {
      row.saved = false
      // this.storeState.userData.savedFunders.pop(row.domain)
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
      } else {
        // save funder to users db
        this.updateUserData()
      }
    },
    applyNow() {
      this.$router.push({ name: 'Apply'})
    },
    applyToFunder(row) {
      this.saveFunder(row)
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
      } else {
        let url = `http://${row.domain}/?ref=trypaperio`
        window.open(url, '_blank');
      }
    },
    moreInfo(row) {
      // this.saveFunder(row)
      if (!this.storeState.isLoggedIn || !this.storeState.user.publicAddress) {
        this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
      } else {
        let url = `http://${row.domain}/?ref=trypaperio`
        window.open(url, '_blank');
      }
    },
    filterFunders() {
      this.filteredFunders = this.funderData
      if (!this.storeState.isLoggedIn) {
        this.filteredFunders = this.funderData.slice(0, 10)
      }
      if (this.revenue.value > 0) {
        this.filteredFunders = this.filteredFunders.filter(row => row.min_annual_revenue <= this.revenue.value)
      }
      if (this.product.value != 'all') {
        this.filteredFunders = this.filteredFunders.filter(row => row.loan_type === this.product.value)
      }
      if (this.rating.value != 'all') {
        let ls = []
        for (let index = 0; index < this.filteredFunders.length; index++) {
          const row = this.filteredFunders[index]
          let rating = this.formatRank(row.paper_rank)
          if (rating <= this.rating.value) ls.push(row)
        }
        this.filteredFunders = ls
      }
      if (this.searchTerm.length > 0) {
        this.filteredFunders = this.filteredFunders.filter(row => row.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
      }
      
    },
    getFunders() {
      this.gotFunders = false
      const path = this.getApiUrl('get_funders')
      let d = {}
      axios.post(path, d)
        .then((res) => {
          console.log('got get_funders: ', res.data)
          this.gotFunders = true
          // this.funderColumns = Object.keys(res.data.columns).filter(c => !['domain', 'data'].includes(c))
          for (let index = 0; index < res.data.data.length; index++) {
            const element = res.data.data[index];
            element.saved = false
          }
          this.funderData = reactive(res.data.data)
          if (this.storeState.isLoggedIn) {
            this.filteredFunders = this.funderData
          } else {
            this.filteredFunders = this.funderData.slice(0, 10)
            // this.filteredFunders = sample(this.funderData, 5);
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    getFunded() {
      this.$router.push({ name: 'Apply', params: { userId: 123 }})
    },
    goToFunders() {
      this.$router.push({ name: 'Landing', params: { userId: 123 }})
    },
    getRequiredMrr() {
      return ((this.loan.amount / this.loan.multiple) / 1000).toFixed(0)
    },
    setPayoutPeriod(po) {
      this.loan.payoutPeriod = po
      this.updatingPayoutPeriod = false
    },
    calcLoan() {
      let month = 0
      this.loan.months = []
      this.loan.years = []
      this.loan.totalPayback = 0
      this.loan.totalInterest = 0
      while (this.loan.term > month) {
        var payback = 0
        var payout = 0
        var balance = 0
        // payout = this.loan.amount / this.loan.payoutPeriod.months
        if (month <= this.loan.payoutPeriod.months - 1) {
          payout = this.loan.amount / this.loan.payoutPeriod.months
        }
        if (month == 0) {
          balance = payout
        } else {
          let prevMonth = this.loan.months[month-1]
          balance = payout + prevMonth.interest + prevMonth.balance
        }
        let interest = balance * (this.loan.rate / 12)
        
        if (month > 23) { //start paying back
          let mo23Bal = this.loan.months[22].balance
          let prevMonth = this.loan.months[month-1]
          payback = (mo23Bal / 12) + prevMonth.interest + prevMonth.payout
        } else if (month > 11) { //start paying interest
          payback = this.loan.months[month-1].interest
        }
        balance = balance - payback
        this.loan.totalPayback+=payback
        this.loan.totalInterest+=interest
        let m = {
          month: month,
          payback: payback,
          payout: payout,
          balance: balance,
          interest: interest
        }
        this.loan.months.push(m)
        if (Object.keys(this.yearLookup).includes(String(month))) {
          m.label = this.yearLookup[month]
          this.loan.years.push(m)
        }
        month+=1
      }
    },
    formatMoney(m) {
      return (m / 1000).toFixed(1)
    },
  },
  watch: {
    'revenue': {
        handler: function () {
          this.filterFunders()
        },
        deep: true
    },
    'product': {
        handler: function () {
          this.filterFunders()
        },
        deep: true
    },
    'rating': {
        handler: function () {
          this.filterFunders()
        },
        deep: true
    },
    'searchTerm': {
        handler: function () {
          this.filterFunders()
        },
        deep: false
    },
    'filteredFunders': {
        handler: function () {
          this.updateSavedFunders()
        },
        deep: true
    },
  },
}
/* eslint-disable no-unused-vars */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .typed-cursor {
    font-weight: 300 !important;
  }
</style>
