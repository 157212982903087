// import axios from 'axios';

export const store = {
  state: {
      showDebugStuffNow: false,
      isLoggedIn: false,
      checkedLogin: false,
      gotUserData: false,
      user: {},
      userData: {
        savedFunders: []
      },
  }
}