
import {createRouter, createWebHistory} from 'vue-router'
import Landing from '../views/Landing.vue'

// Vue.use(VueRouter)

const routerHistory = createWebHistory()

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
]

const router = new createRouter({
  mode: 'history',
  history: routerHistory,
  base: process.env.BASE_URL,
  routes: routes
})

export default router
